<template>
  <div class="w-100 d-flex">
    <div class="chat-content chat-bot info-right p-2 w-100">
      <div class="d-flex justify-content-between flex-wrap">
        <h5>{{$t('creator.send_link_contents')}}</h5>
        <p class="text-info cursor-pointer" @click="urls.push({url: '', id: utils.getUniqueIndex()})">{{ $t('creator.addLinkNew') }}</p>
      </div>

      <b-list-group>
        <b-list-group-item v-for="(i, index) in urls" :key="index" class="p-1 m-0">
          <b-form-group class="m-0">
            <div class="d-flex">
              <b-form-input :state="utils.urlStateExpression(i.url)" id="input-domain" v-model="i.url" placeholder="https://" type="url"></b-form-input>
              <b-button variant="flat-secondary" class="ml-1" @click="deletLink(i.id)">
                <feather-icon icon="TrashIcon"></feather-icon>
              </b-button>
            </div>
          </b-form-group>
        </b-list-group-item>
      </b-list-group>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="light" @click="urls = []">{{$t('creator.cancel')}}</b-button>
        <b-button :disabled="!disabledButton() || urls.length === 0" class="blue-button-next-step" variant="blue-button-next-step" @click="uploadUrls()">
          <b-spinner class="spinner-waiting-service" v-if="is_uploading"></b-spinner>
          {{$t('creator.submit')}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import utils from '@/libs/utils';
import chat_service from '@/services/chat';


export default {
  name: 'uploadLinksChat',
  components: {
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      utils,
      urls: [{url: '', id: utils.getUniqueIndex()}],
      is_uploading: false,
    }
  },
  methods: {
    deletLink(id) {
      this.urls = this.urls.filter((item) => item.id !== id);
    },
    disabledButton() {
      let counter_link = 0;
      this.urls.forEach((link) => {
        if (utils.urlStateExpression(link.url)) counter_link += 1;
      })
      return (counter_link === this.urls.length);
    },
    uploadUrls() {
      const formattedUrls = this.urls.map(i => this.formatUrl(i.url));
      chat_service.uploadLinksInChat(this.chat.uuid, { links: formattedUrls }).then(() => {
        this.is_uploading = false;
        this.$emit('uploaded_contents');
      });
    },
    formatUrl(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`
      }
      if (url.startsWith('http://')) {
        url = url.replace('http://', 'https://');
      }
      return url;
    }


  },
}
</script>

<style scoped>
.info-right {
  border-left: 7px solid #55A6C4;
  background: white;
}
.spinner-waiting-service {
  width: 18px;
  height: 18px;
  margin-right: 0.5em;
}
</style>
