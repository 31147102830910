<template>
  <div class="w-100 d-flex">
    <div class="mr-2">
      <b-avatar
        size="36"
        class="avatar-border-2 box-shadow-1"
        :src="require('@/assets/images/mascot/dil_small.jpg')"
      />
    </div>
    <div class="chat-body w-100">
      <div class="chat-content chat-bot box-info">
        <h5 class="mb-0">Gracias por enviar tu pregunta!</h5>
        <b-button class="btn-green" variant="btn-green">Actualiza tu plan</b-button>
        <span class="text-box-success avenir-medium">Si el Talento contesta el chat empezará y podrás hablar por aquí. Por cierto ¿Ya pensaste tener la membresía de Anunciante Premium? Es necesaria para poder Chatear y además poder crear campañas y los creadores se propongan en ellas.</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton
} from 'bootstrap-vue'

export default {
  name: 'upgradePlan',
  components: {
    BAvatar,
    BButton
  },
  props: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
.box-info {
  width: 100%;
  max-width: 95% !important;
  background: #DDF8EE !important;
  border: 2px solid #57DAAC !important;
  border-radius: 5px !important;
  padding: 5px;
}
.btn-green {
  color: #fff;
  background-color:  #348267 !important;
}
</style>
