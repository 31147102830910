<template>
  <div class="main-container-files" 
    @mouseleave="show_drag_box = false" 
    @dragover="show_drag_box = true" 
    @dragend="show_drag_box = false"
    @mouseout="show_drag_box = false"
  >
    <div class="background-left"/>
    <div class="hover-dropit" @dragover.prevent @drop="handleDrop" v-if="show_drag_box">
      <feather-icon icon="ArrowDownIcon" size="50" class="animation-arrow"></feather-icon>
      <span class="border-top-widthed pt-1 h3">
        Drop it
      </span>
    </div>
    <div class="d-flex flex-column justify-content-between h-100">
      <div>
        <h5>Upload files</h5>
        <p class="subtitle-file">You are limited to 1 file.</p>
      </div>
      <div class="drag-and-drop-files" @click="$refs['file-upload-input'].$refs['input'].click()">
        <feather-icon icon="PlusIcon" class="mr-05" size="20"></feather-icon>
        Drop or Click
        <b-form-file 
          accept=".jpeg, .jpg, .png, .mp4, .mov" 
          multiple 
          v-model="files_model" 
          ref="file-upload-input" 
          class="d-none" 
          @change="changeFile"
          :disabled="files.length > 10"
        ></b-form-file>
      </div>
      <div class="my-1">
        <div v-for="(preview, index) in previews" :key="index" class="box-container-avatar">
          <div>
            <b-avatar 
              :icon="!preview.preview ? 'camera-video' : ''"
              :src="preview.preview" class="mr-1 rounded-image" square></b-avatar>
            <span>{{preview.name}}</span>
          </div>
          <b-button variant="flat-secondary" @click="deleteFromFiles(preview)">
            <feather-icon icon="Trash2Icon"></feather-icon>
          </b-button>
        </div>
      </div>
      <p class="text-danger text-center" v-if="files.length > 10">You exceed the limit of files</p>
      <div class="align-self-end">
        <b-button variant="flat-secondary" @click="$emit('close_upload_files')">Cancel</b-button>
        <b-button :disabled="files.length === 0 || files.length > 10" class="blue-button ml-1" variant="blue-button" @click="sendFiles">Submit</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormFile,
  BAvatar,
} from 'bootstrap-vue';
import { getThumbnails } from 'video-metadata-thumbnails';
import utils from '@/libs/utils';

export default {
  name: 'uploadFiles',
  components: {
    BButton,
    BFormFile,
    BAvatar,
  },
  data() {
    return {
      files_model: [],
      show_drag_box: false,
      previews: [],
      files: []
    }
  },
  methods: {
    handleDrop(event) {
      event.preventDefault();
      this.files_model = [];
      this.show_drag_box = false;
      const droppedFiles = event.dataTransfer.files;
      for (let i = 0; i < droppedFiles.length; i++) {
        this.files_model.push(droppedFiles[i]);
      }
      this.changeFile();
    },
    deleteFromFiles(preview) {
      this.previews = this.previews.filter(p => p.id !== preview.id);
      this.files = this.files.filter(f => f.id !== preview.id);
    },
    changeFile() {
      setTimeout(() => {
        const urls_blobs = [];
        this.files_model.forEach((file) => {
          const id_unique = utils.getUniqueIndex();
          file.id = id_unique;
          const url_blob = URL.createObjectURL(file);
          if (!file.type.includes('video')) {
            urls_blobs.push({preview: url_blob, name: file.name, id: id_unique});
          } else {
            urls_blobs.push({preview: null, name: file.name, id: id_unique});
            const thumbnail = getThumbnails(url_blob, {
              quality: 0.6
            });
            thumbnail.then((value) => {
              const url_blob_thubnail = URL.createObjectURL(value[0].blob);
              this.previews.map((p) => {
                if (p.id  === id_unique) p.preview = url_blob_thubnail
              })
            });
          }
        });
        this.previews = this.previews.concat(urls_blobs);
        this.files = this.files.concat(this.files_model);
      }, 200);
    },
    sendFiles() {
      this.$emit('send_files', this.files);
      this.$emit('close_upload_files');
    }
  }
}
</script>

<style scoped>

.box-container-avatar {
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-container-avatar:hover {
  background-color: #cccccc1c;
  border-radius: 1em;
}
.border-top-widthed {
  border-top: 4px solid #ccc;
}
.rounded-image {
  border-radius: 1em !important;
}
.drag-and-drop-files {
  padding: 1em;
  border: 3px dashed #ccc;
  display: flex;
  align-items: center;
  border-radius: 1em;
  justify-content: center;
  cursor: pointer;
  color: #b4b4b4;
}
.main-container-files {
  position: absolute;
  bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 95%;
  padding: 1em 1em 1em 2em;
  border-radius: 1em;
  background-color: white;
  z-index: 1000 ;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.background-left {
  background-color: #0096fe;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
}
.subtitle-file {
  font-family: 'avenir-medium';
}
.hover-dropit {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column  ;
  justify-content: center;
  align-items: center;
  color: #bebebe;
  cursor: pointer;
  z-index: 100;
}
.animation-arrow {
  animation: arrow 1s infinite;
}
@keyframes arrow {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>