<template>
  <div class="w-100">
    <div class="chat-content chat-bot info-right">
      <div class="p-1">
        <h5>Pregúntale al Talento</h5>
        <span class="text-muted">Antes de ponerte en contacto con el Talento generalmente se tienen este tipo de preguntas en común que ayudan a iniciar la conversación o ir directo</span>

        <b-form-group class="mt-2" v-slot="{ ariaDescribedby }">
          <b-form-radio class="mt-1" v-model="reason" :aria-describedby="ariaDescribedby" name="some-radios" value="¿Cuál es el presupuesto mínimo para poder trabajar contigo?">¿Cuál es el presupuesto mínimo para poder trabajar contigo?</b-form-radio>
          <b-form-radio class="mt-1" v-model="reason" :aria-describedby="ariaDescribedby" name="some-radios" value="¿Tus precios son negociables?">¿Tus precios son negociables?</b-form-radio>
          <b-form-radio class="mt-1" v-model="reason" :aria-describedby="ariaDescribedby" name="some-radios" value="¿Te interesaría colaboar en una campaña que ofreza compensación con experiencias o producto?">¿Te interesaría colaboar en una campaña que ofreza compensación con experiencias o producto?</b-form-radio>
          <b-form-radio class="mt-1" v-model="reason" :aria-describedby="ariaDescribedby" name="some-radios" value="¿Cuándo es la fecha más cercana en tu agenda para poder trabajar contigo?">¿Cuándo es la fecha más cercana en tu agenda para poder trabajar contigo?</b-form-radio>
        </b-form-group>
        
        <div class="mt-1">
          <b-button @click="submitRequestPrice()" variant="info" :disabled="reason === ''">{{ $t('creator.submit') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormRadio
} from 'bootstrap-vue'
import chat_service from '@/services/chat';

export default {
  name: 'requestPrice',
  components: {
    BButton,
    BFormGroup,
    BFormRadio
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: ''
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
    submitRequestPrice() {
      chat_service.priceRequest(this.chat.uuid, this.reason).then(response => {
        console.log(response);
        this.$emit('update_chat')
      })
    }
  },
  watch: {
  }
}
</script>

<style>
.check-position {
  position: absolute;
  left: 60px;
}
.box-info {
  width: 100%;
  max-width: 95% !important;
  color: #ADB0B4 !important;
  background-color: #F7F9FB !important;
  border: 2px solid #c8c8c8;
  padding: 5px;
}
.text-box-success {
  color: #506263;
}
.box-success {
  width: 100%;
  max-width: 95% !important;
  color: #4E5B5B !important;
  background-color: #DDF8EE !important;
  border: 2px solid #A9D7C9;
}
.info-right {
  border-left: 7px solid #55A6C4;
  background: white;
}
</style>
