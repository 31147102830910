<template>
  <div>
    <div class="m-2" v-if="show_title">
      <h4>{{ $t('creator.campaign') }}</h4>
    </div>
    <hr v-if="show_title"/>
    <header-preview-casting 
      class="m-1"
      :data_header="{title: casting_call.title, cover_image: casting_call.cover_image ? utils.getAssetUrl(casting_call.cover_image) : casting_call.cover_url, uuid: casting_call.uuid}" 
      :color="casting_call.main_color"
      :is_sidebar="is_sidebar"
    />

    <body-preview-casting-call :casting_call="casting_call" class="p-1 pb-10" :is_sidebar="is_sidebar"></body-preview-casting-call>
  </div>
</template>

<script>
import headerPreviewCasting from '@/views/components/casting-calls/headerPreviewCasting.vue';
import bodyPreviewCastingCall from '@/views/components/casting-calls/bodyPreviewCastingCall.vue';
import utils from '@/libs/utils'

export default {
  name: 'detailCastingCall',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    show_title: {
      type: Boolean,
      default: true
    },
    is_sidebar: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    headerPreviewCasting,
    bodyPreviewCastingCall
  },
  data() {
    return {
      utils,
    }
  },
  created() {
  },
  destroyed() {
  },
  methods: {
  },
  watch: {
  }
}
</script>