<template>
  <div class="sidebar-left left-chat-container">
    <div class="sidebar">
      <div class="sidebar-content card">

        <!-- Header -->
        <div class="chat-fixed-search left-chat-container">
          <div class="align-items-center w-100 left-chat-container px-1 dropdown-sales-options d-flex" v-if="show_input_search">
            <div class="sidebar-profile-toggle">
              <feather-icon
                icon="ChevronLeftIcon"
                class="cursor-pointer"
                @click="show_input_search = false"
              />
            </div>
            <b-input-group class="input-group-merge ml-1 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="query"
                :placeholder="$t('search_conversation')"
                ref="query_search_input"
              />
            </b-input-group>
          </div>
          <div class="dropdown-sales-options" v-else>
            <b-dropdown block no-caret id="dropdown-options-chat" variant="outline-gray-light" menu-class="w-100">
              <template #button-content>
                <div class="text-left">
                  <span class="">
                     {{$t('creator.options_selectChat').find((option) => option.value === option_selected_dropdown).text}}
                    <feather-icon icon="ChevronDownIcon"></feather-icon>
                  </span>
                </div>
              </template>
              <b-dropdown-item @click="changeDropdown(option.value)" v-for="option in $t('creator.options_selectChat')" :key="option.value" :disabled="option.disabled">{{option.text}}</b-dropdown-item>
            </b-dropdown>
            <feather-icon class="search-icon-dropdown" icon="SearchIcon" @click="showInputSearch()"></feather-icon>
          </div>
          <div class="w-100 d-flex justify-content-center align-items-center hamburger-responsive-options">
            <b-dropdown block no-caret id="dropdown-options-chat" variant="outline-gray-light">
              <template #button-content>
                <feather-icon icon="MenuIcon" size="20" class="cursor-pointer"></feather-icon>
              </template>
              <b-dropdown-item @click="changeDropdown(option.value)" v-for="option in $t('creator.options_selectChat')" :key="option.value" :disabled="option.disabled">{{option.text}}</b-dropdown-item>
              <b-dropdown-header>
                <b-input-group class="input-group-merge ml-1 round">
                  <b-input-group-prepend is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="query"
                    placeholder="Search..."
                    ref="query_search_input"
                  />
                </b-input-group>
              </b-dropdown-header>
            </b-dropdown>
          </div>
        </div>
        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area vs-con-loading__container"
          id="filter-with-loading"
        >
          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list left-chat-container">
            <element-chat
              v-for="(chat, index) in chats_children"
              :key="chat.id"
              tag="li"
              :chat="chat"
              ref="chat_users_list"
              @click="openChat(chat.id, index)"
            />
          </ul>
          <div>
            <no-results v-if="chats_children.length === 0" :border="'border-card-chats'" :size_avatar="'60px'" :subtitle="$t('creator.chatsNull')" :show_button="false" />
          </div>  
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup,
  BDropdownHeader,
  BInputGroupPrepend,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import elementChat from './elementChat.vue'

export default {
  name: 'chatLeftSide',
  components: {
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BDropdownHeader,
    VuePerfectScrollbar,
    elementChat,
  },
  data() {
    return {
      query: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      chats_children: this.chats,
      show_input_search: false,
      option_selected_dropdown: this.value_drop,
      options_select: [
        {value: 'all_conversations', text: 'Todas las conversaciones'},
        {value: 'proposals_chat', text: 'Propuestas'},
        {value: 'sales_chats', text: 'Ventas'},
        {value: 'archived_chats', text: 'Conversaciones archivadas', disabled: true}
      ]
    }
  },
  created() {
    setTimeout(() => {
      if (this.$route.params.chat_uuid !== undefined) this.openChat(this.$route.params.chat_uuid, this.chats_children.indexOf((this.chats_children.find((chat) => chat.uuid === this.$route.params.chat_uuid))));
    }, 500);

  },
  props: {
    chats: {
      type: Array,
      default: () => []
    },
    value_drop: {
      type: String,
    }
  },
  methods: {
    loaderFilterChats() {
      this.$vs.loading({
        background: 'rgba(255, 255, 255, 1)',
        container: '#filter-with-loading'
      })
      setTimeout(() => {
        this.$vs.loading.close('#filter-with-loading > .con-vs-loading')
      }, 500);
    },
    showInputSearch() {
      this.show_input_search = true;
      setTimeout(() => {
        this.$refs.query_search_input.focus();
      }, 200);
    },
    changeDropdown(value) {
      this.loaderFilterChats()
      this.option_selected_dropdown = value;
      this.$emit('filter_chats', value);
    },
    searchQuery() {
      this.chats_children = this.chats.filter((chat) => (chat.user.first_name.toLowerCase().indexOf(this.query.trim().toLowerCase()) !== -1) || (chat.user.last_name.toLowerCase().indexOf(this.query.trim().toLowerCase()) !== -1))
    },
    openChat(chat_id ,index) {
      this.$emit('open-chat', chat_id);
      this.$emit('close_sidebar_chats');
      this.clearActives();
      this.$refs['chat_users_list'][index].$el.children[0].classList.toggle('d-none');
      this.$refs['chat_users_list'][index].$el.classList.toggle('bg-white');
    },
    clearActives() {
      this.chats_children.forEach((i, index) => {
        this.$refs['chat_users_list'][index].$el.children[0].classList.add('d-none');
        this.$refs['chat_users_list'][index].$el.classList.remove('bg-white');
      });
    }
  },
  watch: {
    query() {
      this.searchQuery();
    }
  }
}
</script>
<style scoped>
.border-card-chats {
  border-color: transparent !important;
}
.left-chat-container, .chat-application .sidebar-content, .chat-application .sidebar-content .chat-user-list-wrapper {
  transform: none !important;
  position: relative !important;
  transition: none !important;
}
.search-icon-dropdown {
  position: absolute;
  right: 1em;
  top: 1.8em;
  cursor: pointer;
  z-index: 100;
}
.chat-application .sidebar-content .chat-fixed-search {
  display: block !important;
  padding: 0 !important;
}
.dropdown-sales-options {
  height: 65px;
}
@media(max-width: 900px) {
  .left-chat-container,
  .sidebar-content,
  .chat-user-list-wrapper {
    width: 80px !important;
  }
  .dropdown-sales-options {
    display: none !important;
  }
}
@media(min-width: 901px) {
  .hamburger-responsive-options {
    display: none !important;
  }
}
</style>
<style>
#dropdown-options-chat .dropdown-menu {
  transform: translate3d(-1px, 57px, 0px) !important;
}
#dropdown-options-chat .btn {
  height: 65px !important;
}
#div-with-loading .con-vs-loading{
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,.1)
}
</style>
