<template>
  <div :class="`loader-video-brandme ${position_class}`">
    <div class="d-flex justify-content-center flex-wrap">
      <video :src="require('@/assets/videos/loader_brandme_ia.mp4')" :autoplay="true" :loop="true" ref="video"></video>
      <h6 class="w-100 text-center avenir-medium px-2">{{ $t(text_loading) }}</h6>
    </div>
  </div>
</template>

<script>

export default {
  title: 'LoaderVideoBrandme',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    text_loading: {
      type: String,
      default: 'creator.generandinProposal'
    },
    position_class: {
      type: String,
      default: 'position-fixed'
    }
  },
  data() {
    return {
      show: this.loaded,
      video_src: require('@/assets/videos/loader_brandme_ia.mp4'),
    }
  },
  mounted() {
    this.$refs.video.play();
  },
  methods: {
    onVideoLoaded() {
      this.$refs.video.play();
    },
  }
};
</script>

<style lang="scss" scoped>

.position-fixed {
  position: fixed;
  z-index: 100000;
  height: 100vh;
}
.position-relative {
  position: absolute;
  height: 80vh;
}
.loader-video-brandme {
  background-color: white;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  cursor: progress;

  video {
    margin-top: 5em;
    display: block;
    width: 400px;
    height: 200px;
  }
}
</style>