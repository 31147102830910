<template>
  <div class="w-100 d-flex">
    <div class="main-container-revision">
      <div class="background-left-revision"/>
      <div class="d-flex align-items-center w-100 mb-1">
        <b-avatar class="mr-1" :src="utils.getAssetUrl(brand.avatar)"></b-avatar>
        <div>
          <strong>{{brand.first_name}} {{brand.last_name}}</strong> <small class="date-chat-revision">{{utils.getFormatTimeDifferenceChat(message.sended_at)}}</small>
          <p class="avenir-medium m-0">{{ $t('creator.changeRequest') }}</p>
        </div>

        <feather-icon icon="EditIcon" class="float-right-icon-revision"></feather-icon>
      </div>
      <div>
        <p>{{message.message}}</p>
        <p class="m-0" v-if="!chat.casting_call">{{revisions_remaining}} {{ $t('creator.revisionRemaining') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,  
} from 'bootstrap-vue';
import utils from '@/libs/utils';

export default {
  name: 'revisionChat',
  components: {
    BAvatar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      utils,
      brand: this.chat.brand,
      revisions_remaining: this.chat.revisions_remaining,
    }
  },
}
</script>

<style>
.date-chat-revision {
  font-family: 'avenir-light';
  font-size: 11px;
  margin: 0 0 0 0.5em;
  color: #808080;
}

.background-left-revision {
  background-color: #0096fe;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
}
.main-container-revision {
  position: relative;
  background: white;
  border-radius: 1em;
  padding: 2em;
  border: 1px solid #ccccccb0;
  overflow: hidden;
  width: 100%;
}
.float-right-icon-revision {
  position: absolute;
  right: 2em;
  top: 2em
}
</style>
