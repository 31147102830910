<template>
  <component
    :is="tag"
    v-on="$listeners"
    class="element-chat-left"
  > 
    <div :class="`badge-active d-none`"></div>
    <b-avatar
      size="42"
      :src="utils.getAssetUrl(chat.user.avatar)"
      class="badge-minimal"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ chat.user.first_name }} {{ chat.user.last_name }}
      </h5>
      <p class="card-text text-truncate">
        {{ getLastMessageText() }}
      </p>
    </div>
    <div
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{ chat.lastMessage && chat.lastMessage.sended_at ? formatDateToMonthShort(chat.lastMessage.sended_at, { hour: 'numeric', minute: 'numeric' }) : '' }}</small>
      <div
        v-if="chat.unseenMsgs > 0"
        pill
        class="dot-color-unmessages"
      ></div>
    </div>
  </component>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import utils from '@/libs/utils';

export default {
  name: 'elementChat',
  components: {
    BAvatar,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      utils,
    }
  },
  created() {
  },
  methods: {
    getLastMessageText() {
      if (this.chat.lastMessage) {
        if (this.chat.lastMessage.type_message === 'file')
          return 'Te han enviado un archivo'
  
        return this.chat.lastMessage.message
      }
      return "Escribe el primer mensaje";
    }
  },
  setup() {
    return { formatDateToMonthShort }
  },
}
</script>

<style scoped>
.dot-color-unmessages {
  height: 16px;
  width: 16px;
  background-color: #005cf9;
  border-radius: 10em;
}
@media(max-width: 900px) {
  .chat-info,
  .chat-meta {
    display: none !important;
  }
}
.chat-meta {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.badge-active {
  width: 5px;
  height: 200px;
  background: linear-gradient(to bottom, #0094fc, #1efdc6);
  position: absolute;
  left: 0;
  top: 0;
}
.element-chat-left {
  overflow: hidden;
  position: relative;
  background-color: #F8F9FA;
  border-bottom: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
}
.chat-application .sidebar-content .chat-user-list-wrapper li {
  padding: 16px;
}
</style>
