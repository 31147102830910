import axios from './axios_interceptors'
import config from '@/services/config'
import { tokenExpired } from '@/auth/utils'

export default {
  createChatBrandmeTalent(data) {
    return axios.post(`${config.urlCore}/api/v3/chat/create/brandme-talent/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  priceRequest(chat_uuid, reason) {
    return axios.post(`${config.urlCore}/api/v3/chat/message/price-request/${chat_uuid}/`,
      {reason},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getChats(data) {
    return axios.post(`${config.urlCore}/api/v3/chat/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getDetailChat(chat_uuid) {
    return axios.get(`${config.urlCore}/api/v3/chat/${chat_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  sendMessageText(chat_uuid, message) {
    return axios.post(`${config.urlCore}/api/v3/chat/send/message/${chat_uuid}/`,
      {message},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  markAsReaded(chat_uuid) {
    return axios.get(`${config.urlCore}/api/v3/chat/mark/seen/${chat_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  uploadFilesInChat(chat_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/chat/send/file/${chat_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  uploadLinksInChat(chat_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/chat/send-links/${chat_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  acceptChat(user_proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/proposals/accept/${user_proposal_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  declineChat(user_proposal_uuid, reason) {
    return axios.post(`${config.urlCore}/api/v3/proposals/reject/${user_proposal_uuid}/`,
      {reason},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  uploadContentsChat(chat_uud, files) {
    return axios.post(`${config.urlCore}/api/v3/chat/upload-contents/${chat_uud}/`,
      files,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  requestRevisionChat(chat_uud, message, revision_url) {
    return axios.post(`${config.urlCore}/api/v3/chat/${revision_url}/${chat_uud}/`,
      {message},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  acceptContents(chat_uud, accept_url) {
    return axios.get(`${config.urlCore}/api/v3/chat/${accept_url}/${chat_uud}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  }
}