<template>
  <div class="w-100 d-flex">
    <div class="mr-2">
      <b-avatar
        size="36"
        class="avatar-border-2 box-shadow-1"
        :src="require('@/assets/images/mascot/dil_small.jpg')"
      />
    </div>
    <div class="chat-body w-100">
      <div class="chat-content chat-bot box-info">
        <h5 class="mb-0 text-white">{{ $t('chat.declinedChat') }}</h5>
        <span class="text-box-success avenir-medium text-white">{{ $t('chat.chatNoLonger') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'messageDeclineChat',
  components: {
    BAvatar
  },
  props: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
.box-info {
  width: 100%;
  max-width: 95% !important;
  background: #ff0068 !important;
  border: 2px solid #ff0068 !important;
  border-radius: 5px !important;
  padding: 5px;
}
</style>
