<template>
  <div class="w-100 pb-2">
    <div class="chat-avatar" v-if="!is_declining">
      <b-avatar
        size="36"
        class="avatar-border-2 box-shadow-1"
        :src="require('@/assets/images/mascot/dil_small.jpg')"
      />
    </div>
    <div class="chat-body">
      <div class="chat-content chat-bot box-success" v-if="!is_declining">
        <h5>{{ $t('creator.orderFunded') }}</h5>
        <span class="text-box-success">{{ $t('creator.thisOrder') }}</span>
        <div>
          <b-button @click="accpetChat()" variant="success" class="mr-2 mt-1">{{ $t('creator.accept') }}</b-button>
          <b-button @click="rejectChat()" variant="outline-dark" class="mt-1">{{ $t('creator.decline') }}</b-button>
        </div>
      </div>
      <div v-else class="chat-content chat-bot info-right">
        <h5>{{ $t('creator.decline') }}</h5>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="decline_reason" :aria-describedby="ariaDescribedby" name="some-radios" value="Aschedule_busy">{{ $t('creator.tooBusy') }}</b-form-radio>
          <b-form-radio v-model="decline_reason" :aria-describedby="ariaDescribedby" name="some-radios" value="no_longer_offering">{{ $t('creator.noLonger') }}</b-form-radio>
          <b-form-radio v-model="decline_reason" :aria-describedby="ariaDescribedby" name="some-radios" value="under_contractual_obligation">{{ $t('creator.imUnder') }}</b-form-radio>
          <b-form-radio v-model="decline_reason" :aria-describedby="ariaDescribedby" name="some-radios" value="personal_reason">{{ $t('creator.leeryToWork') }}</b-form-radio>
          <b-form-radio v-model="decline_reason" :aria-describedby="ariaDescribedby" name="some-radios" value="other">{{ $t('creator.otherOption') }}</b-form-radio>
        </b-form-group>
        <div class="mt-1">
          <b-button @click="cancelRejectChat()" variant="light" class="mr-2">{{ $t('creator.cancel') }}</b-button>
          <b-button :disabled="!valid_reason" @click="submitDeclineChat()" variant="blue-button" class="blue-button">{{ $t('creator.submit') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BFormGroup,
  BFormRadio
} from 'bootstrap-vue'
import chat_service from '@/services/chat';
import utils from '../../../../libs/utils';

export default {
  name: 'acceptDeclineChat',
  components: {
    BAvatar,
    BButton,
    BFormGroup,
    BFormRadio
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      is_declining: false,
      decline_reason: '',
      valid_reason: false,
      interval_loading: null
    }
  },
  created() {
  },
  methods: {
    showLoader() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    hideLoader() {
      clearInterval(this.interval_loading)
      this.$vs.loading.close()
    },
    accpetChat() {
      this.showLoader()
      chat_service.acceptChat(this.chat.user_proposal.uuid).then(() => {
        this.hideLoader()
        this.$emit('accept_chat')
      })
    },
    rejectChat() {
      this.is_declining = true
    },
    cancelRejectChat() {
      this.is_declining = false
    },
    submitDeclineChat() {
      this.showLoader()
      chat_service.declineChat(this.chat.user_proposal.uuid, this.decline_reason).then(() => {
        this.hideLoader()
        this.$emit('declined_chat')
        this.is_declining = false
      })
    }
  },
  watch: {
    decline_reason(new_value) {
      if (new_value) this.valid_reason = true
      else this.valid_reason = false
    }
  }
}
</script>

<style>
.text-box-success {
  color: #506263;
}

.box-success {
  width: 100%;
  max-width: 95% !important;
  color: #4E5B5B !important;
  background-color: #DDF8EE !important;
  border: 2px solid #A9D7C9;
}
.info-right {
  border-left: 7px solid #0096fe !important;
  background: white;
}
</style>
